import { checkCaptcha } from 'lib/checkCaptcha';
import './styles/index.scss';

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

// const setVh = () => {
//   const vh = window.innerHeight * 0.01;
//   document.documentElement.style.setProperty('--vh', `${vh}px`);
// };

// setVh();
// window.addEventListener('resize', setVh);

const scrollTo = (el: HTMLElement | null) => {
  const topOffset: number = el ? el.offsetTop - 64 : 0;

  if ('scrollBehavior' in document.documentElement.style) {
    window.scrollTo({
      top: topOffset,
      behavior: 'smooth'
    });
  } else {
    window.document.documentElement.scrollTop = topOffset;
  }
};

const toggleSpinner = (element: HTMLElement, param: boolean) => {
  if (element.classList.contains('loading') || !param) {
    element.classList.remove('loading');
    element.querySelector('.spinner')?.remove();
    element.removeAttribute('disabled');
  } else {
    element.classList.add('loading');
    const spinner = document.createElement('div');
    spinner.className = 'spinner';
    element.setAttribute('disabled', 'true');

    for (let i = 0; i < 5; i++) {
      spinner.innerHTML += '<div></div>';
    }

    element.appendChild(spinner);
  }
}

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll('[href="#access"]').forEach(el => {
    el.addEventListener('click', () => {
      scrollTo(document.getElementById('form'));
      setTimeout(() => {
        (document.querySelector('#form [type=email]') as HTMLElement | undefined)?.focus();
      }, 100);
    });
  });

  document.getElementById('form')?.addEventListener('submit', async event => {
    event.preventDefault();

    const $form = event.target as HTMLFormElement;
    const $button = $form.querySelector('button[type="submit"]') as HTMLElement;

    toggleSpinner($button, true);
    $form.querySelectorAll('.error').forEach(el => el.remove());

    try {
      const token = await checkCaptcha('PRE_SIGNUP');
      const formData = new FormData(event.target as HTMLFormElement);
      formData.append('captcha', token);

      const response = await fetch('/request.php', {
        method: 'POST',
        headers: {
          'Accept': 'application/json'
        },
        body: formData
      });


      if (response.ok) {
        const $successHTML = `<div class="title">You’re In!</div><div class="text">You’ve successfully signed up for early access.<br/>We’ll notify you at ${formData.get('email')} when the service launches.</div>`;

        (event.target as HTMLFormElement).reset();

        $form.classList.add('success');
        $form.innerHTML = $successHTML;

        window.dataLayer?.push({'event': 'presale-submit-success'});
      } else {
        const error = await response.json();

        const errorElement = document.createElement('div');
        errorElement.className = 'error';
        errorElement.innerHTML = error.message;

        $form.append(errorElement);

        window.dataLayer?.push({'event': 'presale-submit-error'});
      }
    } catch (error) {
      const errorElement = document.createElement('div');
      errorElement.className = 'error';
      errorElement.innerHTML = 'An error occurred. Please try again later.';

      $form.append(errorElement);

      window.dataLayer?.push({'event': 'presale-submit-error'});
    } finally {
      toggleSpinner($button, false);
    }
  });

  document.querySelectorAll('.faq .question').forEach((el, index) => {
    const $element = el as HTMLElement;
    const textHeight = (el.querySelector('.text') as HTMLElement)?.scrollHeight || 1000;

    $element.style.setProperty('--text-height', `${textHeight}px`);

    el.addEventListener('click', event => {
      if (el.classList.contains('open')) {
        if ((event.target as HTMLElement).classList.contains('toggler')) {
          el.classList.remove('open');
          window.dataLayer?.push({'event': `faq-item-${index}-close`});
        }

        return;
      }

      el.parentElement?.querySelector('.question.open')?.classList.remove('open');
      el.classList.add('open');
      window.dataLayer?.push({'event': `faq-item-${index}-open`});
    });
  });
});

