declare global {
  interface Window {
    grecaptcha: any;
  }
}

const TOKEN = '6LdxJ-wqAAAAAB3VZaMjGUcTz-oTLtKSuDJC-Vgj';

const initCaptcha = () => {
  return new Promise<void>((resolve, reject) => {
    if (document.getElementById('captcha')) {
      return resolve();
    }

    const container = document.createElement('div');
    container.id = 'captcha';

    document.getElementsByTagName('body')[0].appendChild(container);

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${TOKEN}`;
    script.async = true;

    document.getElementsByTagName('head')[0].appendChild(script);

    script.onload = () => {
      if (!window.grecaptcha?.enterprise) {
        return reject('Captcha failed to load');
      }

      window.grecaptcha.enterprise.ready(async () => {
        resolve();
      });
    };
  });
};

export const checkCaptcha = async (action: string = '') => {
  await initCaptcha();
  return await window.grecaptcha.enterprise.execute(TOKEN, { action });
};
